var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('form-wizard',{staticClass:"steps-transparent",attrs:{"color":"#FF6400","title":null,"subtitle":null,"finish-button-text":"Submit"},on:{"on-complete":_vm.formSubmitted}},[_c('tab-content',{attrs:{"before-change":_vm.validationForm1}},[_c('validation-observer',{ref:"accountRules1",attrs:{"tag":"form"}},[_c('b-row',[_c('b-col',{staticClass:"ml-1 mb-1",attrs:{"cols":"12","md":"12","lg":"12"}},[_c('h5',[_vm._v(_vm._s(_vm.$t('CREATE_AN_ACCOUNT')))]),_c('h5',[_c('validation-provider',{attrs:{"name":_vm.$t('PRIVACY_POLICY'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{staticClass:"mt-1",attrs:{"id":"chk-privacy-policy","name":"chk-privacy-policy","value":"1","unchecked-value":""},model:{value:(_vm.user.GDPR_accepted),callback:function ($$v) {_vm.$set(_vm.user, "GDPR_accepted", $$v)},expression:"user.GDPR_accepted"}},[_c('h5',[_vm._v(" "+_vm._s(_vm.$t('APPROVE_BISEVO'))),_c('b-link',{attrs:{"target":"_blank","href":"https://www.bisevo.se/sv/bisevointegritetspolicy"}},[_vm._v(" "+_vm._s(_vm.$t('PRIVACY_POLICY'))+" ")])],1)]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('b-col',{staticClass:"ml-1 mb-1",attrs:{"cols":"12","md":"12","lg":"12"}},[_c('b-alert',{attrs:{"show":_vm.message_1!=null,"dismissible":"","variant":"danger"},on:{"dismissed":function($event){_vm.message_1=null}}},[_c('div',{staticClass:"alert-body"},[_c('span',[_vm._v(_vm._s(_vm.message_1)+" ")])])])],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"12","lg":"12"}},[_c('b-col',{attrs:{"cols":"12","md":"12","lg":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('FIRST_NAME'),"label-for":"id-firstname"}},[_c('validation-provider',{attrs:{"name":_vm.$t('FIRST_NAME'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"custom-input",attrs:{"id":"id-firstname","maxlength":"45","placeholder":_vm.$t('FIRST_NAME'),"state":errors.length > 0 ? false:null},model:{value:(_vm.user.firstname),callback:function ($$v) {_vm.$set(_vm.user, "firstname", $$v)},expression:"user.firstname"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"12","lg":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('LAST_NAME'),"label-for":"id-lastname"}},[_c('validation-provider',{attrs:{"name":_vm.$t('LAST_NAME'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"custom-input",attrs:{"id":"id-lastname","maxlength":"45","placeholder":_vm.$t('LAST_NAME'),"state":errors.length > 0 ? false:null},model:{value:(_vm.user.lastname),callback:function ($$v) {_vm.$set(_vm.user, "lastname", $$v)},expression:"user.lastname"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"12","lg":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('EMAIL_ADDRESS'),"label-for":"id-email"}},[_c('validation-provider',{attrs:{"name":_vm.$t('EMAIL_ADDRESS'),"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"custom-input",attrs:{"id":"id-email","placeholder":_vm.$t('EMAIL_ADDRESS'),"maxlength":"60","state":errors.length > 0 ? false:null},on:{"blur":function($event){return _vm.isExsitEmail()}},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"12","lg":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('MOBILE_NUMBER'),"label-for":"id-phonenumber"}},[_c('validation-provider',{attrs:{"name":_vm.$t('MOBILE_NUMBER'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('VuePhoneNumberInput',{attrs:{"default-country-code":_vm.phonePayload.countryCallingCode},on:{"update":_vm.handlePhoneNumberInput},model:{value:(_vm.user.phonenumber),callback:function ($$v) {_vm.$set(_vm.user, "phonenumber", $$v)},expression:"user.phonenumber"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1)],1),_c('tab-content',[_c('b-row',[_c('b-col',{staticClass:"ml-1 mb-1",attrs:{"cols":"12","md":"12","lg":"12"}},[_c('p',{domProps:{"innerHTML":_vm._s(_vm.$t('ONBOARDED_TEXT'))}})]),_c('b-col',{staticClass:"ml-1 mb-1",attrs:{"cols":"12","md":"12","lg":"12"}},[_c('b-alert',{attrs:{"show":_vm.message_2!=null,"dismissible":"","variant":_vm.message_2_variant},on:{"dismissed":function($event){_vm.message_2=null}}},[_c('div',{staticClass:"alert-body"},[_c('span',[_vm._v(_vm._s(_vm.message_2)+" ")])])])],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"12","lg":"12"}},[_c('validation-observer',{ref:"accountRules2",attrs:{"tag":"form"}},[_c('b-col',{attrs:{"cols":"12","md":"12","lg":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('CONTENT'),"label-for":"id-category"}},[_c('validation-provider',{attrs:{"name":_vm.$t('CONTENT'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticStyle:{"height":"50px"},attrs:{"options":_vm.allCategories,"label":"name","clearable":false,"input-id":"id-category"},on:{"input":function($event){return _vm.fetchTemplates($event)}},model:{value:(_vm.category),callback:function ($$v) {_vm.category=$$v},expression:"category"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"cols":"12","md":"12","lg":"12"}},[(_vm.$i18n.locale === _vm.localeDefault)?_c('h5',[_vm._v(" "+_vm._s(_vm.category.sv_text)+" ")]):_c('h5',[_vm._v(" "+_vm._s(_vm.category.en_text)+" ")])]),_c('b-col',{attrs:{"cols":"12","md":"12","lg":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('COMPANY'),"label-for":"id-companyname"}},[_c('validation-provider',{attrs:{"name":_vm.$t('COMPANY'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"custom-input",attrs:{"id":"id-companyname","placeholder":_vm.$t('COMPANY'),"maxlength":"50","state":errors.length > 0 ? false:null},on:{"blur":function($event){return _vm.isExsitCompany()}},model:{value:(_vm.company.companyname),callback:function ($$v) {_vm.$set(_vm.company, "companyname", $$v)},expression:"company.companyname"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"12","lg":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('START_DATE'),"label-for":"id-startdate"}},[_c('validation-provider',{attrs:{"name":_vm.$t('START_DATE'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control custom-input",attrs:{"id":"id-startdate","config":{ disable:[{from:_vm.disable_from,to:_vm.disable_startdate}]}},model:{value:(_vm.game.startdate),callback:function ($$v) {_vm.$set(_vm.game, "startdate", $$v)},expression:"game.startdate"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }