<template>
  <div>
    <b-row>
      <b-col cols="12">
        <onboarding-wizard />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import OnboardingWizard from './OnboardingWizard.vue'

export default {
  components: {
    BRow,
    BCol,
    OnboardingWizard,
  },
  beforeMount() {
    // Set the body background color to your desired color
    document.body.style.backgroundColor = '#FFFFFF' // Replace with your desired color
  },
}
</script>
