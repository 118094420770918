<template>
  <div>
    <form-wizard
      color="#FF6400"
      :title="null"
      :subtitle="null"
      finish-button-text="Submit"
      class="steps-transparent"
      @on-complete="formSubmitted"
    >
      <!-- account detail tab -->
      <tab-content
        :before-change="validationForm1"
      >
        <validation-observer
          ref="accountRules1"
          tag="form"
        >
          <b-row>
            <b-col
              cols="12"
              md="12"
              lg="12"
              class="ml-1 mb-1"
            >

              <h5>{{ $t('CREATE_AN_ACCOUNT') }}</h5>
              <h5>
                <validation-provider
                  #default="{ errors }"
                  :name="$t('PRIVACY_POLICY')"
                  rules="required"
                >
                  <b-form-checkbox
                    id="chk-privacy-policy"
                    v-model="user.GDPR_accepted"
                    name="chk-privacy-policy"
                    class="mt-1"
                    value="1"
                    unchecked-value=""
                  >
                    <h5> {{ $t('APPROVE_BISEVO') }}<b-link
                      target="_blank"
                      href="https://www.bisevo.se/sv/bisevointegritetspolicy"
                    >  {{ $t('PRIVACY_POLICY') }} </b-link></h5>

                  </b-form-checkbox>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </h5>
            </b-col>
            <b-col
              cols="12"
              md="12"
              lg="12"
              class="ml-1 mb-1"
            >
              <b-alert
                :show="message_1!=null"
                dismissible
                variant="danger"
                @dismissed="message_1=null"
              >
                <div class="alert-body">
                  <span>{{ message_1 }}
                  </span>
                </div>
              </b-alert>
            </b-col></b-row>
          <b-row>
            <b-col
              cols="12"
              md="12"
              lg="12"
            >

              <!-- Field: First Name -->
              <b-col
                cols="12"
                md="12"
                lg="12"
              >
                <b-form-group
                  :label="$t('FIRST_NAME')"
                  label-for="id-firstname"
                >

                  <validation-provider
                    #default="{ errors }"
                    :name="$t('FIRST_NAME')"
                    rules="required"
                  >
                    <b-form-input
                      id="id-firstname"
                      v-model="user.firstname"
                      maxlength="45"
                      :placeholder="$t('FIRST_NAME')"
                      :state="errors.length > 0 ? false:null"
                      class="custom-input"
                    />

                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>

                </b-form-group>
              </b-col>
              <!-- Field: Last Name -->
              <b-col
                cols="12"
                md="12"
                lg="12"
              >
                <b-form-group
                  :label="$t('LAST_NAME')"
                  label-for="id-lastname"
                >

                  <validation-provider
                    #default="{ errors }"
                    :name="$t('LAST_NAME')"
                    rules="required"
                  >
                    <b-form-input
                      id="id-lastname"
                      v-model="user.lastname"
                      maxlength="45"
                      :placeholder="$t('LAST_NAME')"
                      class="custom-input"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>

                </b-form-group>
              </b-col>
              <!-- Field: Email -->
              <b-col
                cols="12"
                md="12"
                lg="12"
              >
                <b-form-group
                  :label="$t('EMAIL_ADDRESS')"
                  label-for="id-email"
                >

                  <validation-provider
                    #default="{ errors }"
                    :name="$t('EMAIL_ADDRESS')"
                    rules="required|email"
                  >
                    <b-form-input
                      id="id-email"
                      v-model="user.email"
                      :placeholder="$t('EMAIL_ADDRESS')"
                      maxlength="60"
                      class="custom-input"
                      :state="errors.length > 0 ? false:null"
                      @blur="isExsitEmail()"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>

                  </validation-provider></b-form-group>
              </b-col>
              <!-- Phone Number -->
              <b-col
                cols="12"
                md="12"
                lg="12"
              >
                <b-form-group
                  :label="$t('MOBILE_NUMBER')"
                  label-for="id-phonenumber"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('MOBILE_NUMBER')"
                    rules="required"
                  >
                    <VuePhoneNumberInput
                      v-model="user.phonenumber"
                      :default-country-code="phonePayload.countryCallingCode"
                      @update="handlePhoneNumberInput"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-col></b-row>
        </validation-observer>
      </tab-content>
      <!-- template -->
      <tab-content>

        <b-row>
          <b-col
            cols="12"
            md="12"
            lg="12"
            class="ml-1 mb-1"
          >
            <p
              v-html="$t('ONBOARDED_TEXT')"
            />
          </b-col>
          <b-col
            cols="12"
            md="12"
            lg="12"
            class="ml-1 mb-1"
          >
            <b-alert
              :show="message_2!=null"
              dismissible
              :variant="message_2_variant"
              @dismissed="message_2=null"
            >
              <div class="alert-body">
                <span>{{ message_2 }}
                </span>
              </div>
            </b-alert>
          </b-col></b-row>
        <b-row>

          <b-col
            cols="12"
            md="12"
            lg="12"
          >

            <validation-observer
              ref="accountRules2"
              tag="form"
            >
              <!-- Field: Category Template -->
              <b-col
                cols="12"
                md="12"
                lg="12"
              >
                <b-form-group
                  :label="$t('CONTENT')"
                  label-for="id-category"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('CONTENT')"
                    rules="required"
                  >
                    <v-select
                      v-model="category"
                      :options="allCategories"
                      label="name"
                      :clearable="false"
                      style="height:50px;"
                      input-id="id-category"
                      @input="fetchTemplates($event)"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="12"
                lg="12"
                class="mb-1"
              >
                <h5 v-if="$i18n.locale === localeDefault">
                  {{ category.sv_text }}
                </h5>
                <h5 v-else>
                  {{ category.en_text }}
                </h5>
              </b-col>
              <!-- Field: Company Name-->
              <b-col
                cols="12"
                md="12"
                lg="12"
              >
                <b-form-group
                  :label="$t('COMPANY')"
                  label-for="id-companyname"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('COMPANY')"
                    rules="required"
                  >
                    <b-form-input
                      id="id-companyname"
                      v-model="company.companyname"
                      :placeholder="$t('COMPANY')"
                      class="custom-input"
                      maxlength="50"
                      :state="errors.length > 0 ? false:null"
                      @blur="isExsitCompany()"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>

                </b-form-group>
              </b-col>
              <!-- Field: Start Date -->
              <b-col
                cols="12"
                md="12"
                lg="12"
              >
                <b-form-group
                  :label="$t('START_DATE')"
                  label-for="id-startdate"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('START_DATE')"
                    rules="required"
                  >
                    <flat-pickr
                      id="id-startdate"
                      v-model="game.startdate"
                      :config="{ disable:[{from:disable_from,to:disable_startdate}]}"
                      class="form-control custom-input"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </validation-observer>
          </b-col>
        </b-row>
      </tab-content>
    </form-wizard>
  </div>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
// eslint-disable-next-line import/no-extraneous-dependencies
import { required, email } from '@validations'
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment'
// eslint-disable-next-line import/no-extraneous-dependencies
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'

import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  VBToggle,
  VBTooltip,
  BAlert,
  BLink,
  BFormCheckbox,
} from 'bootstrap-vue'
import { mapActions, mapState } from 'vuex'
// eslint-disable-next-line import/extensions, import/no-unresolved
import { mixinDate } from '@/constants/mixinDate'
import constants from '@/constants/static.json'
import router from '@/router'
// eslint-disable-next-line import/order
import VuePhoneNumberInput from 'vue-phone-number-input'
// eslint-disable-next-line import/no-extraneous-dependencies, import/order
import parsePhoneNumberFromString from 'libphonenumber-js'

export default {
  components: {
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    BAlert,
    BLink,
    BFormCheckbox,
    VuePhoneNumberInput,
    // eslint-disable-next-line vue/no-unused-components
    parsePhoneNumberFromString,
    // eslint-disable-next-line vue/no-unused-components
    AppTimeline,
    flatPickr,
  },
  directives: { 'b-toggle': VBToggle, 'b-tooltip': VBTooltip, Ripple },
  mixins: [mixinDate],
  data() {
    return {
      game: {
        gamename: '',
        startdate: '',
        enddate: '',
        category_id: '',
        template_id: '',
        visionstatement: constants.GAME_VISION,
      },
      disable_from: '1900-06-11',
      disable_startdate: '',
      phonePayload: {
        countryCode: '',
        isValid: false,
        phoneNumber: '',
        countryCallingCode: constants.DEFULT_COUNTRY_CODE.ISO_COUNTRY_CODE,
        formattedNumber: '',
        nationalNumber: '',
        formatInternational: '',
        formatNational: '',
        uri: '',
        e164: '',
      },
      company: {
        companyname: '',
        nature_of_business: '',
        package_id: 0,
        website: '',
        address_line_1: '',
        address_line_2: '',
        postcode: '',
        city: '',
        state: '',
        country: '',
      },
      user: {
        firstname: '',
        lastname: '',
        language: '',
        phonenumber: '',
        country_code: constants.DEFULT_COUNTRY_CODE.DIALING_CODE,
        email: '',
        GDPR_accepted: '',
      },
      category: '',
      template: '',
      players: '',
      monthlyfees: '',
      email,
      exsitEmail: false,
      exsitCompany: false,
      required,
      localeDefault: constants.LOCALE.SWEDISH,
      languageOptions: constants.LANGUAGES,
      pricePackage: constants.PACKAGES[0],
      packageOptions: constants.PACKAGES,
      categoryOptions: [],
      templatesTask: [],
      message_1: null,
      message_2: null,
      message_2_variant: 'danger',
      options: {
        phone: {
          phone: true,
          phoneRegionCode: 'SE',
          blocks: [2, 3, 3, 2],
        },
      },
    }
  },
  mounted() {
    if (router.currentRoute.query.id === constants.LOCALE.ENGLISH) {
      this.$i18n.locale = constants.LOCALE.ENGLISH
    }
  },
  created() {
    this.$i18n.locale = this.localeDefault
    this.game.startdate = moment().add(0, 'days').format(this.FES_DATE_SQL_FORMAT)
    this.disable_startdate = moment().add(-1, 'days').format(this.FES_DATE_SQL_FORMAT)
    this.fetchAllCategories()
  },
  // eslint-disable-next-line vue/order-in-components
  computed: {
    ...mapState('template', ['allCategories']),
  },
  methods: {
    ...mapActions('onboarding', [
      'fetchUserByEmail',
      'fetchCompany',
      'createOnboarding',
      'fetchTemplatesByID',
      'createSalesLeads']),
    ...mapActions('template', ['fetchAllCategories']),
    formSubmitted() {
      this.$refs.accountRules2.validate().then(success => {
        if (success) {
          this.game.company = this.company
          this.game.company.package_id = this.pricePackage.id
          this.game.gamename = `${this.company.companyname} [G A M E]`
          this.game.user = this.user
          this.game.category_id = this.category.ID
          const endDate = moment(this.game.startdate, this.FES_DATE_SQL_FORMAT)
          this.game.enddate = endDate.add(13, 'days').format(this.FES_DATE_SQL_FORMAT)
          if (this.phonePayload.nationalNumber) {
            this.game.user.phonenumber = this.phonePayload.nationalNumber
            this.game.user.country_code = `+${this.phonePayload.countryCallingCode}`
          }
          this.createOnboarding(this.game).then(response => {
            if (response) {
              this.clearForm()
              const id = this.$i18n.locale
              this.$router.push({
                name: 'onboarding-complete',
                query: { id },
              })
            }
          }).catch(() => {
            this.message_2 = this.$i18n.t('SOMETHING_WENT_WRONG')
          })
        }
      })
    },
    fetchTemplates(e) {
      this.message_2 = null
      this.fetchTemplatesByID(e.ID).then(response => {
        if (response) {
          const template = response.find(i => i.is_private === 0 && i.language === this.$i18n.locale)
          if (template) {
            this.game.template_id = template.ID
          } else {
            this.message_2 = this.$i18n.t('CONTENT_UNAVAILABLE')
          }
        }
      }).catch(() => {
        this.message_2 = this.$i18n.t('SOMETHING_WENT_WRONG')
      })
    },
    handlePhoneNumberInput(payload) {
      // Access the detailed payload from the @input event
      this.phonePayload = payload
    },
    isExsitEmail() {
      this.exsitEmail = false
      this.message_1 = null
      this.fetchUserByEmail(this.user.email).then(response => {
        if (response) {
          this.exsitEmail = response.isExists
          if (this.exsitEmail) this.message_1 = this.$i18n.t('ALREADY_EXIST_EMAIL')
        }
      }).catch(() => {
        this.message_1 = this.$i18n.t('SOMETHING_WENT_WRONG')
      })
    },
    isExsitCompany() {
      this.exsitCompany = false
      this.message_2 = null
      this.fetchCompany(this.company.companyname).then(response => {
        if (response) {
          this.exsitCompany = response.isExists
          if (this.exsitCompany) this.message_2 = this.$i18n.t('ALREADY_EXIST_COMPANY')
        }
      }).catch(() => {
        this.message_2 = this.$i18n.t('SOMETHING_WENT_WRONG')
      })
    },
    validationForm1() {
      this.message_1 = null
      this.message_2 = null
      return new Promise((resolve, reject) => {
        this.$refs.accountRules1.validate().then(success => {
          if (success) {
            if (this.exsitEmail) {
              this.message_1 = this.$i18n.t('ALREADY_EXIST_EMAIL')
              reject()
            } else if (this.exsitCompany) {
              this.message_2 = this.$i18n.t('ALREADY_EXIST_COMPANY')
              reject()
            } else {
              if (this.phonePayload.nationalNumber) {
                this.user.phonenumber = this.phonePayload.nationalNumber
                this.user.country_code = `+${this.phonePayload.countryCallingCode}`
              }
              this.createSalesLeads(this.user)
              resolve(true)
            }
          } else {
            reject()
          }
        })
      })
    },
    clearForm() {
      this.company = ''
      this.game = {
        gamename: '',
        startdate: '',
        enddate: '',
        category_id: '',
        template_id: '',
        visionstatement: constants.GAME_VISION,
      }
      this.user = {
        firstname: '',
        lastname: '',
        language: '',
        phonenumber: '',
        email: '',
      }
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '~vue-phone-number-input/dist/vue-phone-number-input.css';
.fontbadge {
  font-size: 18px;
}
.red-dot {
    color: red;
    font-size: 14px;
    vertical-align: bottom;
}
.custom-input {
  height: 50px; /* Adjust the height to your desired value */
}
</style>
